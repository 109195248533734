import Color from 'color'

export const parsifalRed = Color.rgb('#a00000')
export const parsifalLightRed = Color.rgb('#b40000')
export const parsifalBlue = Color.rgb('#1400f1')
export const parsifalDark = Color.rgb('#313131')
export const parsifalWhite = Color.rgb('#fbfbfb')
export const parsifalGlass = Color.rgb('#ffffff4e')

function makeLighter(i_color : Color, i_amount : number) {
	return i_color.lighten(i_amount).desaturate(i_amount/2)
}

function makeDarker(i_color : Color, i_amount : number) {
	return i_color.darken(i_amount).saturate(i_amount/2)
}

export interface Theme {
	colors: {
		mainRed : string,
		mainBlue : string,
		mainDark : string,
		mainWhite : string,
		text: {
			main : string,
			weak : string,
			alt : string,
		},
		background: {
			stronger : string,
			strong : string,
			main : string,
			weak : string,
			weaker : string,
			alt : string,
			red : string,
			shadow : string,
		},
	},
	gradients: {
		red : string,
	},
	effects: {
		coolHeader: (i_size? : number) => any,
	},
	clickable: (i_isClickable : boolean) => any,
}

const colorTextAlt = makeLighter(parsifalRed, 1.2).string()

export const getTheme = (i_colorScheme : string | null = 'dark') : Theme => ({
	colors: {
		mainRed: parsifalRed.string(),
		mainBlue: parsifalBlue.string(),
		mainDark: parsifalDark.string(),
		mainWhite: parsifalWhite.string(),
		text: {
			main: i_colorScheme === 'light' ? parsifalDark.string() : parsifalWhite.string(),
			weak: i_colorScheme === 'light' ? makeLighter(parsifalDark, 2).string() : makeDarker(parsifalWhite, .4).string(),
			alt: colorTextAlt,
		},
		background: {
			stronger: i_colorScheme === 'light' ? makeDarker(parsifalWhite, .4).string() : makeDarker(parsifalDark, .4).string(),
			strong: i_colorScheme === 'light' ? makeDarker(parsifalWhite, .2).string() : makeDarker(parsifalDark, .2).string(),
			main: i_colorScheme === 'light' ? parsifalWhite.string() : parsifalDark.string(),
			weak: i_colorScheme === 'light' ? makeDarker(parsifalWhite, .1).string() : makeLighter(parsifalDark, .1).string(),
			weaker: i_colorScheme === 'light' ? makeDarker(parsifalWhite, .2).string() : makeLighter(parsifalDark, .2).string(),
			alt: parsifalWhite.string(),
			red: parsifalRed.string(),
			shadow: i_colorScheme === 'light' ? makeDarker(parsifalWhite, .4).alpha(0.8).string() : makeDarker(parsifalDark, .4).alpha(0.8).string(),
		},
	},
	gradients: {
		red: `linear-gradient(to right, ${parsifalRed.string()} 0%, ${parsifalLightRed.string()} 100%)`,
	},
	effects: {
		coolHeader: (i_size : number = 1) => ({
			fontSize: i_size + 'rem',
			textTransform: 'uppercase',
			letterSpacing: '.5em',
			wordSpacing: '.5em',
			fontWeight: 100,
			color: colorTextAlt,
		}),
	},
	clickable: (i_isClickable : boolean = true) => ({
		cursor: i_isClickable ? 'pointer' : 'default',
	}),
})
