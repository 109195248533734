import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import useColorScheme from '../hooks/useColorScheme'
import { Theme } from '../Style'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		width: '6em',
		'&:hover': {
			backgroundColor: theme.colors.background.weak,
		},
	},
	value: {
		color: theme.colors.text.weak,
		fontSize: '.6em',
		transition: 'font-size .5s cubic-bezier(0.68, -0.55, 0.27, 1.55)',
		...theme.clickable(true),
	},
	selectedValue: {
		color: theme.colors.text.main,
		fontSize: '1em',
		...theme.clickable(false),
	},
}))

interface Props {
	className?: string,
}

export default function ColorSchemeSelector(props : Props) {
	const { className } = props
	const classes = useStyles()
	const { userColorScheme, setUserColorScheme } = useColorScheme()

	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			{[
				{ key: 'dark', name: 'Dark theme', },
				{ key: 'light', name: 'Light theme', },
			].map((ctheme) => <div 
				key={ctheme.key} 
				className={classNames({ [classes.value]: true, [classes.selectedValue]: userColorScheme === ctheme.key })}
				onClick={() => ctheme.key !== userColorScheme && setUserColorScheme(ctheme.key)}
				>
				{ctheme.name}
			</div>)}
		</div>
	);
};
