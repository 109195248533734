import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import useDetectColorScheme from "./useDetectColorScheme";
import { useLocalStorage } from "./useLocalStorage";

export default function useColorScheme() {
	const systemColorScheme = useDetectColorScheme()
	const [ storedUserSelectedColorScheme, setStoredUserSelectedColorScheme ] = useLocalStorage<string>('userColorScheme', '')
	
	const { setColorScheme } = useContext(ThemeContext)

	return {
		colorScheme: storedUserSelectedColorScheme || systemColorScheme || 'dark',
		userColorScheme: storedUserSelectedColorScheme,
		setUserColorScheme: (i_colorScheme : string) => {
			setStoredUserSelectedColorScheme(i_colorScheme)
			setColorScheme(i_colorScheme)
		},
	}
}
