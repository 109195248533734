import React from "react";
import { Theme } from "../Style";

export const ColorSchemes = {
	Dark: 'dark',
	Light: 'light',
}

interface ContextValue {
	theme : Theme | null,
	colorScheme : string,
	setColorScheme : (i_colorScheme : string) => void,
}

export const ThemeContext = React.createContext<ContextValue>({ 
	theme: null, 
	colorScheme: ColorSchemes.Dark, 
	setColorScheme: () => {} 
})
