import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Routes } from '../Routing'
import NavLink from './NavLink'
import { Theme } from '../Style'
import { useLocation } from 'react-router'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		background: theme.colors.background.strong,
		color: theme.colors.text.main,
	},
	menu: {
		display: 'flex',
		flexDirection: 'column',
	},
	link: {
		color: theme.colors.text.weak,
	},
	currentLink: {
		fontWeight: 'bold',
		color: theme.colors.text.main,
	},
}))

interface Props {
	className?: string,
}

export default function MainNavBar(props : Props) {
	const { className } = props
	const classes = useStyles()
	const location = useLocation()

	return (
		<nav className={classNames({ [classes.root]: true, [className || '']: className})}>
			<menu className={classes.menu}>
				{[
					Routes.home,
					Routes.singleRaceAnalysis,
					Routes._4FunTeamChampionship,
				].map((item, i) => <li key={i}>
					<NavLink className={classNames({ [classes.link]: true, [classes.currentLink]: location.pathname === item.path })} 
						route={item} />
				</li>)
				}
			</menu>
		</nav>
	);
};
