import React from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Route } from '../Routing'
import { Theme } from '../Style'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		display: 'inline-flex',
		//justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		padding: '1em 1em',
		'&:hover': {
			backgroundColor: theme.colors.background.weak,
		},
	},
}))

interface Props {
	className? : string,
	route : Route,
}

export default function NavLink(props : Props) {
	const { className } = props
	const { route } = props
	const classes = useStyles()
	
	return (
		<Link className={classNames({ [classes.root]: true, [className || '']: className})}
			to={route.path}>
			{route.label}
		</Link>
	);
};
