import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { array } from 'array-n'
import { Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import JSONDataImporter from '../components/JSONDataImporter'
import { RaceResult } from '../services/ParsifalTypes'
import { Theme } from '../Style'
import { formatGap, formatTime } from '../services/formatUtils'
import { arrayToN } from '../services/arrayUtils'
import Color from 'color'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		background: theme.colors.background.main,
		color: theme.colors.text.main,
		display: 'grid',
		gridTemplate: '"title title" auto "importer importer" auto "standings chart" auto / auto 1fr',
		gap: '1em',
	},
	title: {
		gridArea: 'title',
		...theme.effects.coolHeader(2),
	},
	subTitle: {
		...theme.effects.coolHeader(1.4),
	},
	importer: {
		gridArea: 'importer',
	},

	standings: {
		gridArea: 'standings',
	},
	standingsDriverResult: {
		display: 'grid',
		gridTemplate: '"position color name gap" auto / 1em .5em 1fr 6em',
		gap: '1em',
		padding: [ 0, '.25em' ],
	},
	standingsDriverResultHighlighted: {
		backgroundColor: theme.colors.background.alt,
		color: theme.colors.text.alt,
	},
	standingsDriverResultPosition: {
		gridArea: 'position',
		color: theme.colors.text.alt,
		justifySelf: 'end',
	},
	standingsDriverResultColor: {
		gridArea: 'color',
		borderRadius: '.125em',
		margin: [ '.125em', 0 ],
	},
	standingsDriverResultName: {
		gridArea: 'name',
	},
	standingsDriverResultGap: {
		gridArea: 'gap',
		justifySelf: 'end',
	},
	
	chart: {
		gridArea: 'chart',
	},
	chartArea: {
		maxHeight: '32rem',
	},
}))

const useRaceProgressionTooltipStyles = createUseStyles((theme : Theme) => ({
	root: {
		background: theme.colors.background.main,
		color: theme.colors.text.main,
		padding: '1em',
	},
	title: {
		...theme.effects.coolHeader(1),
	},
	standings: {
		fontSize: '.7em',
		display: 'grid',
		gridTemplate: '"position name time" auto / 1em 1fr 6em',
		gap: '1em',
	},
	standingsPosition: { gridArea: 'position', },
	standingsName: { gridArea: 'name', },
	standingsTime: { gridArea: 'time', },
}))

const driverColors = [
	Color.hsl(40, 90, 50),
	Color.hsl(240, 10, 80),
	Color.hsl(20, 80, 60),
	Color.hsl(120, 60, 30),
	Color.hsl(120, 60, 50),
	Color.hsl(180, 80, 20),
	Color.hsl(180, 70, 35),
	Color.hsl(180, 60, 50),
	Color.hsl(180, 50, 65),
	Color.hsl(180, 40, 80),
	Color.hsl(60, 80, 20),
	Color.hsl(60, 75, 35),
	Color.hsl(60, 70, 50),
	Color.hsl(60, 65, 65),
	Color.hsl(60, 60, 80),
	Color.hsl(270, 80, 20),
	Color.hsl(270, 75, 35),
	Color.hsl(270, 70, 50),
	Color.hsl(270, 65, 65),
	Color.hsl(270, 60, 80),
	Color.hsl(10, 80, 20),
	Color.hsl(10, 75, 35),
	Color.hsl(10, 70, 50),
	Color.hsl(10, 65, 65),
	Color.hsl(10, 60, 80),
]

function driverColor(i_driverPosition : number) {
	return (i_driverPosition - 1) < driverColors.length ? driverColors[i_driverPosition - 1].string() : '#fff'
}

function sectorIndexToLap(i_sectorIndex : number, i_sectorsPerLap : number, i_onlyLap : boolean = true) {
	return i_onlyLap 
		? Math.floor(i_sectorIndex / i_sectorsPerLap).toFixed(0)
		: Math.floor(i_sectorIndex / i_sectorsPerLap).toFixed(0) + 'S' + (i_sectorIndex % i_sectorsPerLap + 1)
}

function getSectorName(i_sectorIndex : number) {
	return [ 'Finish line', 'S1', 'S2' ][i_sectorIndex]
}

interface Props {
	className? : string,
}

interface SectorDriverResult { 
	name : string, 
	sectorTime : number | null, 
	totalTime : number | null 
}

interface SectorInfo {
	lap: number, 
	sector: number,
	sectorIndex: number,
}

function RaceProgressionTooltip(props : any) {
	const classes = useRaceProgressionTooltipStyles()
	const { active, payload, label } : { active : boolean, payload : any[], label : string } = props
	const sectorsPerLap : number = props.sectorsPerLap

	if (active && payload && payload.length) {
		const sectorIndex = parseInt(label)
		const lap = sectorIndexToLap(sectorIndex, sectorsPerLap)
		const sector = sectorIndex % sectorsPerLap
		const sectorName = getSectorName(sector)
		const standings = payload[0].payload.standings

		interface StandingsForSorting { i : number, value : number | null }
		let sortedStandings = standings.map((x : SectorDriverResult, i : number) : StandingsForSorting => ({ i, value: x.totalTime }))
		sortedStandings.sort((sr1 : StandingsForSorting, sr2 : StandingsForSorting) => {
			if (sr1.value === null) return 1
			if (sr2.value === null) return -1
			if (sr1.value < sr2.value) return -1
			if (sr1.value > sr2.value) return 1
			return 0
		})
		sortedStandings = sortedStandings
			.map((x : StandingsForSorting, i : number) : SectorDriverResult => standings[x.i])

		return <div className={classes.root}>
			<header className={classes.title}>Lap {lap} {sectorName}</header>
			{sortedStandings
				.map((sr : SectorDriverResult, i : number, srs : SectorDriverResult[]) => {
					const leaderSR : SectorDriverResult = srs[0]
					return <div className={classes.standings}>
						<span className={classes.standingsPosition}>{sr.totalTime !== null ? i + 1 : '--'}</span>
						<span className={classes.standingsName}>{sr.name}</span>
						<span className={classes.standingsTime}>{sr.totalTime && leaderSR.totalTime
							? i === 0 ? formatTime(sr.totalTime) : formatGap(sr.totalTime - leaderSR.totalTime, 0)
							: 'N/A'
						}</span>
					</div>
				})}
		</div>
	}
	return null
}

export default function SingleRaceAnalysisPage(props : Props) {
	const { className } = props
	const classes = useStyles()

	const [ raceResultsData, setRaceResultsData ] = useState<string | null>(null)
	const [ raceResults, setRaceResults ] = useState<RaceResult | null>(null)
	const [ highlightedDriverIndex, setHighlightedDriverIndex ] = useState<number | null>(null)

	const winnerDriverResult = raceResults ? raceResults.drivers.find(d => d.position === 1) : null
	const sectorsPerLap = winnerDriverResult ? (
			winnerDriverResult.laps[0].s3Time ? 3 : winnerDriverResult.laps[0].s2Time ? 2 : winnerDriverResult.laps[0].s1Time ? 1 : 0
		) : 0
	const sectorsCount = sectorsPerLap * (winnerDriverResult?.lapsNumber || 0)
	const sectorTimes = raceResults?.drivers.map(dr => {
		return arrayToN(sectorsCount).map(s => {
			const l = Math.floor(s / sectorsPerLap)
			const ls = s % sectorsPerLap
			if (l >= dr.laps.length) {
				return null
			}
			let sectorTime = 0
			switch (ls) {
				case 0: sectorTime = dr.laps[l].s1Time; break
				case 1: sectorTime = dr.laps[l].s2Time; break
				case 2: sectorTime = dr.laps[l].s3Time; break
			}
			return sectorTime
		}) || null
	}) || null
	const lapChartData : (SectorInfo & { standings: SectorDriverResult[] | null })[] = [
		{
			lap: 0, sector: 0, sectorIndex: 0,
			standings: raceResults && raceResults.drivers.map((dr, di) => ({
				name: dr.name, 
				sectorTime: 0,
				totalTime: 0,
			}))
		}
	]
	if (sectorTimes) {
		for (let si = 0; si < sectorsCount; ++si) {
			const sectorRow = {
				lap: Math.floor(si / sectorsPerLap),
				sector: si % sectorsPerLap + 1,
				sectorIndex: si + 1,
				standings: raceResults && raceResults.drivers.map((dr, di) => {
					const sectorTime = sectorTimes[di][si]
					const previousSectorCumulatedTime = si === 0 
						? 0 
						: lapChartData[si - 1 + 1].standings?.find(standing => standing.name === dr.name)?.totalTime || 0
					return {
						name: dr.name,
						sectorTime: sectorTimes[di][si],
						totalTime: sectorTime && (sectorTime + previousSectorCumulatedTime),
					}
				}),
			}
			lapChartData.push(sectorRow)
		}
	}
	const driversLapChartData = raceResults?.drivers?.map(dr => {
		return lapChartData.map(sectorData => ({ 
			lap: sectorData.lap, 
			sector: sectorData.sector, 
			...sectorData.standings?.find(standing => standing.name === dr.name), 
		}))
	}).flat()

	function handleRaceResultsImported(i_raceResultsData : string | null) : void {
		setRaceResultsData(i_raceResultsData)
		if (i_raceResultsData) {
			const importedRaceResults = JSON.parse(i_raceResultsData) as RaceResult
			setRaceResults(importedRaceResults)
		}
	}
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<h1 className={classes.title}>Single Race Analysis</h1>

			<JSONDataImporter<RaceResult> title="Race results" data={raceResultsData} className={classes.importer}
				onDataImported={handleRaceResultsImported}
				dataVisualizer={rr => <p>Race results imported</p>}
				/>

			<div className={classes.standings}>
				<h2 className={classes.subTitle}>Race Final Standings</h2>
				{raceResults && raceResults.drivers.map((dr, i) => {
					const gap = dr.dnf
						? 'DNF'
						: dr.position === 1 
							? formatTime(dr.finishTime) 
							: formatGap(dr.finishTime - (winnerDriverResult?.finishTime || 0), (winnerDriverResult?.lapsNumber || 0) - dr.lapsNumber)
					return <div key={dr.position} className={classNames({
							[classes.standingsDriverResult]: true,
							[classes.standingsDriverResultHighlighted]: i === highlightedDriverIndex,
						})}
						onMouseEnter={() => { setHighlightedDriverIndex(i) }}
						onMouseLeave={() => { setHighlightedDriverIndex(null) }}
						>
						<span className={classes.standingsDriverResultPosition}>{dr.position}</span>
						<span className={classes.standingsDriverResultColor} style={{ backgroundColor: driverColor(dr.position) }}></span>
						<span className={classes.standingsDriverResultName}>{dr.name}</span>
						<span className={classes.standingsDriverResultGap}>{gap}</span>
					</div>
				})}
			</div>
			
			<div className={classes.chart}>
				<h2 className={classes.subTitle}>Race Progression</h2>
				{/* { lapChartData && sectorsCount > 0 &&
					<div>
						<div>
							<button type="button"></button>
						</div>
					</div>
				} */}
				{ lapChartData && sectorsCount > 0 && 
					<ResponsiveContainer className={classes.chartArea}>
						<LineChart 
							data={lapChartData} 
							width={800} height={600} 
							margin={{ top: 25, right: 5, left: 60, bottom: 5, }}
							>
							<CartesianGrid stroke="#666" strokeDasharray="3 3" />
							<XAxis 
								orientation="top"
								label="Laps"
								dataKey="sectorIndex"
								tickFormatter={x => x % sectorsPerLap === 0 ? sectorIndexToLap(x, sectorsPerLap) : ''}
								domain={['dataMin', 'dataMax']} />
							<Brush 
								dataKey="sectorIndex" 
								tickFormatter={x => sectorIndexToLap(x, sectorsPerLap, false)}
								/>
							<YAxis reversed={true} label="Gap from leader" unit="s" type="number" />
							<Tooltip content={<RaceProgressionTooltip sectorsPerLap={sectorsPerLap} />} />
							{raceResults?.drivers.map((dr, di) => 
								<Line 
									key={di} 
									fill={driverColor(dr.position)}
									stroke={driverColor(dr.position)}
									strokeWidth={di === highlightedDriverIndex ? 3 : 1}
									dot={di === highlightedDriverIndex}
									dataKey={(data) => {
										const driverTotalTime = data.standings.find((x : SectorDriverResult) => x.name === dr.name)?.totalTime
										if (!driverTotalTime) {
											return null
										}
										const leaderTotalTime = data.standings.map((x : SectorDriverResult) => x.totalTime)
											.filter((x : number | null) => x)
											.reduce((min : number, x : number) => x < min ? x : min)
										const lineData = driverTotalTime - leaderTotalTime
										return lineData
									}} 
									/>
							)}
						</LineChart>
					</ResponsiveContainer>
				}
			</div>
		</div>
	)
}
