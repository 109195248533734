import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '../Style'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {},
	
	header: {
		display: 'grid',
		gridTemplate: '"title button" auto / 1fr auto',
		background: theme.gradients.red,
		padding: '1em',
		fontWeight: 'bold',
		cursor: 'pointer',
	},
	headerTitle: {
		gridArea: 'title',
	},
	headerButton: {
		gridArea: 'button',
	},

	content: {
		padding: '1em',
		backgroundColor: theme.colors.background.weak,
	},
	contentOpen: {
		display: 'block',
	},
	contentClosed: {
		display: 'none',
	},
}))

interface Props {
	className? : string,
	isOpen? : boolean,
	header : JSX.Element | string,
	content : JSX.Element | string,
}

export default function CollapsiblePanel(props : Props) {
	const { className } = props
	const classes = useStyles()
	const [ isOpen, setIsOpen ] = useState(props.isOpen ?? true)

	useEffect(() => {
		setIsOpen(props.isOpen ?? true)
	}, [ props.isOpen ])
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<header className={classes.header} onClick={() => setIsOpen(!isOpen)}>
				<span className={classes.headerTitle}>{props.header}</span>
				<span className={classes.headerButton}>{isOpen ? '▲' : '▼'}</span>
			</header>
			<div className={classNames({ [classes.content]: true, [classes.contentOpen]: isOpen, [classes.contentClosed]: !isOpen })}>
				{props.content}
			</div>
		</div>
	);
};
