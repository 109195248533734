import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '../../Style'
import { SeriesStandings4FunTeamChampionship, TeamEventResult4FunTeamChampionship } from '../../services/DataManager'
import TeamEventResultDetails from './TeamEventResultDetails'
import { RuleSets } from '../../hooks/use4FunTeamChampionship'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {},
	title: {
		textAlign: 'center',
		fontSize: '2rem',
		margin: [ '1em', 0 ],
	},
	standings: {
		margin: [ '4em', 0, '1em' ],
		'&:first-of-type': {
			marginTop: '1em',
		},
	},
	standingsTitle: {
		textAlign: 'center',
		...theme.effects.coolHeader(1.4),
	},
	subStandings: {
		margin: [ '2em', 0, '1em' ],
		'&:first-of-type': {
			marginTop: '1em',
		},
	},
	subStandingsTitle: {
		textAlign: 'center',
		...theme.effects.coolHeader(),
	},
	overallStandingsRow: {
		display: 'grid',
		gridTemplate: '"position name points" 2em / 2em 1fr 6em',
		alignItems: 'center',
		maxWidth: '30rem',
		margin: [ 0, 'auto' ],
	},
	eventStandingsRow: {
		display: 'grid',
		gridTemplate: '"position name points" 2em "details details details" auto / 2em 1fr 6em',
		alignItems: 'center',
		maxWidth: '30rem',
		margin: [ 0, 'auto' ],
	},
	position: {
		gridArea: 'position',
		textAlign: 'center',
		color: theme.colors.text.alt,
		fontWeight: 'bold',
	},
	teamName: {
		gridArea: 'name',
	},
	points: {
		gridArea: 'points',
		textAlign: 'right',
		color: theme.colors.text.alt,
	},
}),
{ name: 'SeriesStandings' })

interface Props {
	className? : string,
	seriesStandings : SeriesStandings4FunTeamChampionship | null,
	rulesSet : RuleSets,
}

export default function SeriesStandings(props : Props) {
	const { className } = props
	const classes = useStyles()
	const seriesStandings = props.seriesStandings
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			{!seriesStandings &&
				<p>No standings available</p>
			}
			{seriesStandings &&
			<React.Fragment>
				<h2 className={classes.title}>{seriesStandings.series}</h2>
				
				<section className={classes.standings}>
					<h3 className={classes.standingsTitle}>Overall standings</h3>
					{seriesStandings.teamStandings.sort((a, b) => a.position - b.position).map(ts => <div className={classes.overallStandingsRow} key={ts.position}>
						<div className={classes.position}>{ts.position}</div>
						<div className={classes.teamName}>{ts.teamName}</div>
						<div className={classes.points}>{ts.points}</div>
					</div>)}
				</section>

				<section className={classes.standings}>
					<h3 className={classes.standingsTitle}>Weekly standings</h3>
					{seriesStandings.events.map((event, eventIndex) => 
						{
							const eventTeamResults = ([] as Array<TeamEventResult4FunTeamChampionship>)
								.concat(...seriesStandings.teamStandings.map(ts => ts.results))
								.filter(ter => ter.eventName === event.name)
								.sort((a, b) => a.eventPosition - b.eventPosition)
							
							return <section key={eventIndex} className={classes.subStandings}>
								<h4 className={classes.subStandingsTitle}>{event.name}</h4>
								{
									eventTeamResults.map(ter => 
										<TeamEventResultDetails key={ter.eventPosition} className={classes.eventStandingsRow} teamEventResult={ter} rulesSet={props.rulesSet} />
									)
								}
							</section>
						}
					)}
				</section>
			</React.Fragment>
			}
		</div>
	);
};
