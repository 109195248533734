import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '../Style'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		background: theme.colors.background.main,
		color: theme.colors.text.main,
	},
}))

interface Props {
	className?: string,
}

export default function HomePage(props : Props) {
	const { className } = props
	const classes = useStyles()
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<h1>Dashboard</h1>
		</div>
	);
};
