import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '../../Style'
import { RaceResult, SeasonFastestLapLeaderboards, SeasonResults } from '../../services/ParsifalTypes'
import SeriesStandings from './SeriesStandings'
import { Standings4FunTeamChampionship } from '../../services/DataManager'
import { RuleSets } from '../../hooks/use4FunTeamChampionship'
import JSONDataImporter from '../JSONDataImporter'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {},
	dataImportersContainer: {
		display: 'grid',
		gridTemplate: '"importer importer" auto / 1fr 1fr',
		gap: '2rem',
	},
	jsonImport: {
		height: '1em',
		border: 'none',
		resize: 'none',
		overflow: 'hidden',
	},
}))

interface Props {
	className? : string,
	standings : Standings4FunTeamChampionship | null,
	onNewRaceResults? : (i_raceResults : Array<RaceResult>) => void,
	onNewFastestLapsLeaderboards? : (i_fastestLapLeaderboards : SeasonFastestLapLeaderboards) => void,
	rulesSet : RuleSets,
}

export default function StandingsPanel(props : Props) {
	const { className } = props
	const classes = useStyles()

	const [ raceResultsData, setRaceResultsData ] = useState<string | null>(null)
	const [ fastestLapsLeaderboardsData, setFastestLapsLeaderboardsData ] = useState<string | null>(null)
	const onNewRaceResults = props.onNewRaceResults
	const onNewFastestLapsLeaderboards = props.onNewFastestLapsLeaderboards

	function handleRaceResultsImported(i_raceResultsData : string | null) : void {
		setRaceResultsData(i_raceResultsData)
		if (i_raceResultsData) {
			const importedRaceResults = JSON.parse(i_raceResultsData) as SeasonResults
			if (onNewRaceResults) {
				onNewRaceResults(importedRaceResults.raceResultsList)
			}
		}
	}

	function handleFastestLapsLeaderboardsImported(i_fastestLapsLeaderboardsData : string | null) : void {
		setFastestLapsLeaderboardsData(i_fastestLapsLeaderboardsData)
		if (i_fastestLapsLeaderboardsData) {
			const importedLeaderboards = JSON.parse(i_fastestLapsLeaderboardsData) as SeasonFastestLapLeaderboards
			if (onNewFastestLapsLeaderboards) {
				onNewFastestLapsLeaderboards(importedLeaderboards)
			}
		}
	}
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<div className={classes.dataImportersContainer}>
				<JSONDataImporter<Array<RaceResult>> title="Race results" 
					data={raceResultsData} 
					onDataImported={handleRaceResultsImported} 
					dataVisualizer={rr => <p>{rr.length} race results were found</p>}
					/>
				<JSONDataImporter<SeasonFastestLapLeaderboards> title="Fastest laps leaderboards" 
					data={fastestLapsLeaderboardsData} 
					onDataImported={handleFastestLapsLeaderboardsImported} 
					dataVisualizer={fll => <p>{fll.qualifyingFastestLaps.length} events were found</p>}
					/>
			</div>
			{
				props.standings
				? <SeriesStandings 
					seriesStandings={(props.standings && props.standings?.seriesStandings.length > 0) ? props.standings.seriesStandings[0] : null} 
					rulesSet={props.rulesSet} 
					/>
				: <p>No standings available</p>
			}
		</div>
	)
}
