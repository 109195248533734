import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import Button from './Button'
import { Theme } from '../Style'
import useClipboard from '../hooks/useClipboard'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		marginBottom: '1em',
	},
	title: {
		textAlign: 'center',
		...theme.effects.coolHeader(1.4),
		marginBottom: '1em',
	},

	importModes: {
		display: 'grid',
		gridTemplate: '"mode mode mode" auto / 1fr 1fr 1fr',
		gap: '1em',
	},
	importMode: {
		borderWidth: '2px',
		borderStyle: 'solid',
		borderColor: theme.colors.mainRed,
		backgroundColor: theme.colors.background.weaker,
		padding: '1em',
		textAlign: 'center',
		'& > header': {
			...theme.effects.coolHeader(),
		},
	},
	importModeNotAvailable: {
		//pointerEvents: 'none',
		position: 'relative',
		'&:hover::after': {
			content: "'Coming soon!'",
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			display: 'flex',
			backgroundColor: theme.colors.background.shadow,
			justifyContent: 'center',
			alignItems: 'center',
			...theme.effects.coolHeader(),
		},
		'& > *': {
			filter: 'blur(1px) grayscale(60%) opacity(30%)',
		},
	},
	importModeDetails: {
		'& > p': {
			marginTop: '1em',
		},
	},

	dataReport: {
		textAlign: 'center',
		'& > header': {
			...theme.effects.coolHeader(),
			margin: [ '1em', 0 ],
		},
	},
}),
{
	name: 'JSONDataImporter'
})

interface Props {
	className? : string,
	title? : string,
	data? : string | null,
	onDataImported? : (i_data : string | null) => void,
	dataVisualizer? : (i_data : any) => JSX.Element,
}

export default function JSONDataImporter<TData>(props : Props) {
	const { className } = props
	const classes = useStyles()

	const { read: readFromClipboard } = useClipboard()

	const [ dataObject, setDataObject ] = useState<TData | null>(null)

	useEffect(() => {
		setDataObject(props.data ? JSON.parse(props.data) as TData : null)
	}, [ props.data ])

	function importFromClipboard() {
		readFromClipboard && readFromClipboard()
		.then(clipboardContent => {
			if (props.onDataImported) {
				props.onDataImported(clipboardContent)
			}
		})
	}

	function clearData() {
		if (props.onDataImported) {
			props.onDataImported(null)
		}
	}
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<header className={classes.title}>{props.title}</header>
			{
				props.data && dataObject
				
				? <section className={classes.dataReport}>
					<header>Data successfully imported</header>
					{props.dataVisualizer && <div>{props.dataVisualizer(dataObject)}</div>}
					<div><Button label="Clear data" onPress={() => clearData()} /></div>
				</section>
				
				: <React.Fragment>
					<section className={classes.importModes}>
						<section className={classes.importMode}>
							<header>Paste from clipboard</header>
							<div className={classes.importModeDetails}>
								<p>The data must have been previously copied into the clipboard.</p>
								<p><Button label="Import" onPress={() => importFromClipboard()} /></p>
							</div>
						</section>
						<section className={classNames({ [classes.importMode]: true, [classes.importModeNotAvailable]: true })}>
							<header>Type or edit</header>
							<div className={classes.importModeDetails}>
								<p>You can edit the data in a text editor.</p>
								<p><Button label="Open text editor" /></p>
							</div>
						</section>
						<section className={classNames({ [classes.importMode]: true, [classes.importModeNotAvailable]: true })}>
							<header>Load from file</header>
							<div className={classes.importModeDetails}>
								<p>Load a JSON file.</p>
								<p><Button label="Open the file" /></p>
							</div>
						</section>
					</section>
				</React.Fragment>
			}
		</div>
	)
}
