import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import groupBy, {} from '../services/arrayUtils'
import DataManager, { DriverEventResult4FunTeamChampionship, DriverRaceResult4FunTeamChampionship, Event4FunTeamChampionship, Roster4FunTeamChampionship, SeriesStandings4FunTeamChampionship, Standings4FunTeamChampionship, Team4FunTeamChampionship, TeamEventResult4FunTeamChampionship, TeamSeriesResult4FunTeamChampionship } from "../services/DataManager"
import { DriverRaceResult, FastestLapLeaderboardResult, RaceResult, SeasonFastestLapLeaderboards } from "../services/ParsifalTypes"

interface DriverTicket {
	name : string,
	teamName : string,
}

export enum RuleSets {
	Edition3,
}

function getMinimumNumberOfRacesForADriverInAnEvent(i_ruleSet : RuleSets) : number {
	switch (i_ruleSet) {
		case RuleSets.Edition3:
			return 2
	}
}

function getMinimumNumberOfDriversForATeamInAnEvent(i_ruleSet : RuleSets) : number {
	switch (i_ruleSet) {
		case RuleSets.Edition3:
			return 2
	}
}

export function getRacePointsModifierByParticipants(i_driversCount : number, i_split : number, i_rulesSet : RuleSets) : number {
	switch (i_rulesSet) {
		case RuleSets.Edition3:
			if (i_split === 1 && i_driversCount <= 8) {
				return -2
			}
			else if (i_split === 1 && i_driversCount <= 11) {
				return -1
			}
			return 0
		default:
			return 0
	}
}

function getQualifyingFastestLapPoints(i_position : number, i_rulesSet : RuleSets) : number {
	switch (i_rulesSet) {
		case RuleSets.Edition3:
			switch (i_position) {
				case 1: return 5
				case 2: return 4.5
				case 3: return 4
				case 4: return 3.5
				case 5: return 3
				case 6: return 2.5
				case 7: return 2
				case 8: return 1.5
				case 9: return 1
				case 10: return 0.5
				default: return 0
			}
	}
}

function getRaceFastestLapPoints(i_position : number, i_rulesSet : RuleSets) : number {
	switch (i_rulesSet) {
		case RuleSets.Edition3:
			switch (i_position) {
				case 1: return 10
				case 2: return 9
				case 3: return 8
				case 4: return 7
				case 5: return 6
				case 6: return 5
				case 7: return 4
				case 8: return 3
				case 9: return 2
				case 10: return 1
				default: return 0
			}
	}
}

function computeRaceResult(i_driverRaceResult : DriverRaceResult,
	i_raceResult : RaceResult, i_drivers : Array<DriverTicket>, 
	i_ruleSet : RuleSets
	) : DriverRaceResult4FunTeamChampionship {
	let points = 0

	switch (i_ruleSet) {
		case RuleSets.Edition3:
			points = i_driverRaceResult.points + getRacePointsModifierByParticipants(i_raceResult.drivers.length, i_raceResult.split, i_ruleSet)
			break
	}

	return {
		driverName: i_driverRaceResult.name,
		teamName: i_drivers.find(dt => dt.name === i_driverRaceResult.name)?.teamName ?? '',
		eventName: i_raceResult.track.name,
		dateTime: typeof i_raceResult.dateTime === 'string' ? DateTime.fromISO(i_raceResult.dateTime) : i_raceResult.dateTime,
		split: i_raceResult.split,
		participantsCount: i_raceResult.drivers.length,
		raceResultPosition: i_driverRaceResult.position,
		raceResultDNF: i_driverRaceResult.dnf,
		raceResultIncidentPoints: i_driverRaceResult.incidents,
		points: points,
	}
}

function computeEventResult(
	i_driverRaceResults : Array<DriverRaceResult4FunTeamChampionship>, 
	i_qualifyingFastestLapResult : FastestLapLeaderboardResult | null,
	i_raceFastestLapResult : FastestLapLeaderboardResult | null,
	i_trackName : string, 
	i_ruleSet : RuleSets
	) : DriverEventResult4FunTeamChampionship | null {
	if ((!i_driverRaceResults || i_driverRaceResults.length === 0) && !i_qualifyingFastestLapResult && !i_raceFastestLapResult) {
		return null
	}

	let raceResultsPoints = 0
	let qualifyingFastestLapResult = 0
	let raceFastestLapResult = 0
	
	switch (i_ruleSet) {
		case RuleSets.Edition3:
			const minimumNumberOfRaces = getMinimumNumberOfRacesForADriverInAnEvent(i_ruleSet)
			const effectiveNumberOfRaces = i_driverRaceResults.length < minimumNumberOfRaces ? minimumNumberOfRaces : i_driverRaceResults.length
			raceResultsPoints = i_driverRaceResults.map(drr => drr.points).reduce((sum, x) => sum + x, 0) / effectiveNumberOfRaces
			if (i_driverRaceResults.length < minimumNumberOfRaces) {
				raceResultsPoints += 60 * (minimumNumberOfRaces - i_driverRaceResults.length) / effectiveNumberOfRaces // 60 default points, then averaged on the minimum number of results
			}
			
			qualifyingFastestLapResult = i_qualifyingFastestLapResult ? getQualifyingFastestLapPoints(i_qualifyingFastestLapResult.position, i_ruleSet) : 0
			raceFastestLapResult = i_raceFastestLapResult ? getRaceFastestLapPoints(i_raceFastestLapResult.position, i_ruleSet) : 0

			break
	}
	
	const driverName = (i_driverRaceResults.length > 0 && i_driverRaceResults[0].driverName)
		|| (i_qualifyingFastestLapResult && i_qualifyingFastestLapResult.driverName)
		|| (i_raceFastestLapResult && i_raceFastestLapResult.driverName)
		|| ''

	return {
		eventName: i_trackName,
		driverName: driverName,
		results: i_driverRaceResults,
		eventPoints: raceResultsPoints + qualifyingFastestLapResult + raceFastestLapResult,
		raceResultsPoints: raceResultsPoints,
		qualifyingFastestLapResult: qualifyingFastestLapResult, 
		raceFastestLapResult: raceFastestLapResult,
		eventTeamPosition: 0,
		eventOverallPosition: 0,
	}
}

/*function computeTeamEventsResult(
	i_groupedResults : Array<Array<DriverEventResult4FunTeamChampionship>>, 
	i_team : Team4FunTeamChampionship, 
	i_rulesSet : RuleSets
	) : Array<TeamEventResult4FunTeamChampionship> {
	const teamRelatedResults = i_groupedResults.filter(gr => gr.length > 0 && i_team.driversFullName.some(dn => dn === gr[0].driverName))
	const flattenedTeamRelatedResults = ([] as Array<DriverEventResult4FunTeamChampionship>)
		.concat(...teamRelatedResults.map(ders => ders))
	const groupedByEvent = groupBy(flattenedTeamRelatedResults, der => der.eventName)
	return groupedByEvent.map(eventDERs => computeTeamEventResult(eventDERs.items, i_team, i_rulesSet))
}*/

function computeTeamEventResult(
	i_resultsAtEvent : Array<DriverEventResult4FunTeamChampionship>, 
	i_team : Team4FunTeamChampionship, 
	i_eventName : string,
	i_rulesSet : RuleSets
	) : TeamEventResult4FunTeamChampionship {
	const teamRelatedResults = i_resultsAtEvent.filter(der => i_team.driversFullName.some(dn => dn === der.driverName))
	let eventPoints = 0
	
	switch (i_rulesSet) {
		case RuleSets.Edition3:
			const minimumDrivers = getMinimumNumberOfDriversForATeamInAnEvent(i_rulesSet)
			const effectiveNumberOfDrivers = teamRelatedResults.length < minimumDrivers ? minimumDrivers : teamRelatedResults.length
			eventPoints = teamRelatedResults.map(der => der.eventPoints).reduce((sum, x) => sum + x, 0)
			switch (teamRelatedResults.length) {
				case 0:
					eventPoints = 40 + 40 // just a formality, for the sake of the flow
					break
				case 1:
					eventPoints += 50
					break
			}
			eventPoints = Math.round(eventPoints / effectiveNumberOfDrivers)
			break
	}
		
	return {
		teamName: i_team.name,
		eventName: i_eventName,
		driverResults: teamRelatedResults,
		eventPoints: eventPoints,
		eventPosition: 0,
	}
}

export function use4FunTeamChampionship(i_edition : number) : {
	editions : number[] | null,
	createNewEdition : () => number,
	roster : Roster4FunTeamChampionship | null,
	addTeamInRoster : (i_roster : Roster4FunTeamChampionship, i_team : Team4FunTeamChampionship | null) => void,
	editTeamInRoster : (i_roster : Roster4FunTeamChampionship, i_teamIndex : number, i_team : Team4FunTeamChampionship) => void,
	computeStandingsFromResults : (
		i_raceResults : Array<RaceResult> | null, 
		i_fastestLapsLeaderboards : SeasonFastestLapLeaderboards | null,
		i_rulesSet : RuleSets
		) => Standings4FunTeamChampionship | null,
} {
	const [ isEditionsDirty, setEditionsDirty ] = useState<boolean>(false)
	const [ isRostersDirty, setRostersDirty ] = useState<boolean>(false)
	const [ editions, setEditions ] = useState<number[] | null>(null)
	const [ roster, setRoster ] = useState<Roster4FunTeamChampionship | null>(null)
	
	useEffect(() => {
		let mounted = true
		DataManager.getEditions4FunTC()
		.then(i_editions => {
			if (mounted) {
				if (isEditionsDirty || !editions) {
					setEditions(i_editions)
					setEditionsDirty(false)
				}
			}
		})
		return () => {
			mounted = false
		}
	}, [ editions, isEditionsDirty ])
	
	useEffect(() => {
		let mounted = true
		DataManager.getRoster4FunTCByEdition(i_edition)
		.then(i_roster => {
			if (mounted) {
				if (isRostersDirty || !roster) {
					setRoster(i_roster)
					setRostersDirty(false)
				}
			}
		})
		return () => {
			mounted = false
		}
	}, [ i_edition, roster, isRostersDirty ])

	return {
		editions: editions,
		createNewEdition: () => {
			const newEdition = (editions && editions.length > 0) ? editions?.reduce((acc, e) => e > acc ? e : acc) : 1
			DataManager.saveRoster4FunTC({
				edition: newEdition,
				teams: [],
			})
			setEditionsDirty(true)
			return newEdition
		},

		roster: roster,
		addTeamInRoster: (i_roster, i_team) => {
			i_roster.teams.push(i_team || {
				name: 'Team #' + (i_roster.teams.length + 1),
				driversFullName: [],
			})
			DataManager.saveRoster4FunTC(i_roster)
			setRostersDirty(true)
		},
		editTeamInRoster: (i_roster, i_teamIndex, i_team) => {
			if (i_roster.teams.length < i_teamIndex + 1) {
				throw new Error('Cannot edit a team with an index higher than the number of teams.')
			}
			i_roster.teams[i_teamIndex] = i_team;
			DataManager.saveRoster4FunTC(i_roster)
			setRostersDirty(true)
		},

		computeStandingsFromResults: (i_raceResults, i_fastestLapsLeaderboards, i_rulesSet) => {
			if (!i_raceResults && !i_fastestLapsLeaderboards) {
				return null
			}

			const standings : Standings4FunTeamChampionship = {
				seriesStandings: [],
			}

			const validRaceResults = i_raceResults && i_raceResults.filter(rr => rr.drivers.length >= 6 || rr.split > 1)

			const eventsFromRaceResults : Array<string> = groupBy((validRaceResults || []).map(rr => rr.track), track => track.name).map(trackGroup => trackGroup.key)
			
			const eventsFromQualifyingFastestLapsLeaderboards : Array<string> = i_fastestLapsLeaderboards?.qualifyingFastestLaps
				.filter(fll => fll.fastestLaps.length > 0)
				.map(fll => fll.event.code) || []
				const eventsFromRaceFastestLapsLeaderboards : Array<string> = i_fastestLapsLeaderboards?.raceFastestLaps
				.filter(fll => fll.fastestLaps.length > 0)
				.map(fll => fll.event.code) || []

			const events = groupBy([ ...eventsFromRaceResults, ...eventsFromQualifyingFastestLapsLeaderboards, ...eventsFromRaceFastestLapsLeaderboards ], e => e)
				.map(trackGroup => ({
					name: trackGroup.key,
					shortName: trackGroup.key.slice(0, 3),
				}))
			
			const teams = roster?.teams || []

			const drivers = (teams).map(team => team.driversFullName.map(dfn => ({ name: dfn, teamName: team.name })))
				.reduce((flat, drivers) => [ ...flat, ...drivers ], [])
			
			//console.log('Events', events)
			//console.log('Drivers', drivers)

			const driverRaceResults : Array<DriverRaceResult4FunTeamChampionship> = 
				([] as Array<{rr : RaceResult, drr : DriverRaceResult}>)
				.concat(...(validRaceResults || []).map(rr => rr.drivers.map(drr => ({ 
					rr: rr, 
					drr: drr 
				}))))
				.filter(drr => drivers.some(dn => dn.name === drr.drr.name))
				.map(drr => computeRaceResult(drr.drr, drr.rr, drivers, i_rulesSet))
			
			//console.log('Drivers race results', driverRaceResults)

			const teamSeriesResults : Array<TeamSeriesResult4FunTeamChampionship> = 
				teams.map((team : Team4FunTeamChampionship, teamIndex : number) : TeamSeriesResult4FunTeamChampionship => {
					const results = events.map((event: Event4FunTeamChampionship, eventIndex: number): TeamEventResult4FunTeamChampionship => {
						const qualifyingFastestLapLeaderboards = i_fastestLapsLeaderboards?.qualifyingFastestLaps
							.find(efll => efll.event.code === event.name) || null
						const raceFastestLapLeaderboards = i_fastestLapsLeaderboards?.raceFastestLaps
							.find(efll => efll.event.code === event.name) || null

						const driversEventResults = drivers.filter(d => d.teamName === team.name).map(d => computeEventResult(
							driverRaceResults.filter(drr => drr.driverName === d.name && drr.eventName === event.name),
							(qualifyingFastestLapLeaderboards && qualifyingFastestLapLeaderboards.fastestLaps.find(fl => fl.driverName === d.name)) || null,
							(raceFastestLapLeaderboards && raceFastestLapLeaderboards.fastestLaps.find(fl => fl.driverName === d.name)) || null,
							event.name,
							RuleSets.Edition3
						))

						const resultsAtEvent = driversEventResults.filter(der => der !== null).map(x => x as DriverEventResult4FunTeamChampionship)
						return computeTeamEventResult(
							resultsAtEvent, 
							team, 
							event.name,
							RuleSets.Edition3
							)
					})

					return {
						teamName : team.name,
						results: results,
						points: results.map(r => r.eventPoints).reduce((sum, x) => sum + x, 0),
						position: 0,
					}
				})
			//console.log('Teams series results', teamSeriesResults)

			const seriesStandings : SeriesStandings4FunTeamChampionship = {
				series: 'Unknown series',
				events: events,
				teamStandings: teamSeriesResults,
			}

			standings.seriesStandings.push(seriesStandings)

			// Assigning overall positions
			standings.seriesStandings.forEach(sstandings => {
				sstandings.teamStandings.sort((a, b) => {
					const pointsDifference = b.points - a.points
					return pointsDifference
				})
				sstandings.teamStandings.forEach((ts, i) => {
					ts.position = i + 1
				})
			});
			
			// Assigning positions per event
			standings.seriesStandings.forEach(ss => {
				ss.events.forEach(event => {
					const eventTeamResults = ([] as Array<TeamEventResult4FunTeamChampionship>)
					.concat(...seriesStandings.teamStandings.map(ts => ts.results))
					.filter(ter => ter.eventName === event.name)
					eventTeamResults.sort((a, b) => {
						const pointsDifference = b.eventPoints - a.eventPoints
						return pointsDifference
					})
					eventTeamResults.forEach((ter, i) => {
						const actualTer = seriesStandings
							.teamStandings.find(ts => ts.teamName === ter.teamName)
							?.results.find(ter => ter.eventName === event.name)
						if (actualTer) {
							actualTer.eventPosition = i + 1
						}
					})
				})
			})

			//console.log('Standings', standings)
			return standings
		},
	}
}
