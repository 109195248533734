import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { TeamEventResult4FunTeamChampionship } from '../../services/DataManager'
import { printDate, printTime } from '../../services/formatUtils'
import { Theme } from '../../Style'
import { getRacePointsModifierByParticipants, RuleSets } from '../../hooks/use4FunTeamChampionship'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		cursor: 'pointer',
	},
	position: {
		gridArea: 'position',
		textAlign: 'center',
		color: theme.colors.text.alt,
		fontWeight: 'bold',
	},

	teamEventResult: {
		gridArea: 'details',
		backgroundColor: theme.colors.background.strong,
		maxWidth: '30rem',
		margin: [ 0, 'auto' ],
		padding: '1em',
	},
	teamEventResultHeader: {
		fontWeight: 'bold',
		color: theme.colors.text.alt,
		marginBottom: '1em',
	},
	teamName: {},
	
	driverEventResult: {
		marginTop: '1em',
	},
	driverEventResultHeader: {
		display: 'grid',
		gridTemplate: '"driver pointsRR pointsQFL pointsRFL points" auto / 1fr 4em 3em 3em 4em',
	},
	driverName: {
		gridArea: 'driver',
	},
	driverEventRaceResultsPoints: {
		gridArea: 'pointsRR',
		textAlign: 'right',
	},
	driverEventQualiFastestPoints: {
		gridArea: 'pointsQFL',
		textAlign: 'right',
	},
	driverEventRaceFastestPoints: {
		gridArea: 'pointsRFL',
		textAlign: 'right',
	},
	driverEventPoints: {
		gridArea: 'points',
		textAlign: 'right',
		color: theme.colors.text.alt,
	},
	
	driverRaceResult: {
		display: 'grid',
		padding: [ '.5em', 0 ],
		marginLeft: '2em',
		gridTemplate: '"date position participants points" auto "time split participants-penalty points" auto / 6em 5em 5em 1fr',
		fontWeight: '50',
	},
	date: {
		gridArea: 'date',
		textAlign: 'center',
	},
	time: {
		gridArea: 'time',
		textAlign: 'center',
	},
	racePosition: {
		gridArea: 'position',
		textAlign: 'center',
	},
	raceSplit: {
		gridArea: 'split',
		textAlign: 'center',
		fontSize: '.8em',
		color: theme.colors.text.weak,
		alignSelf: 'center',
	},
	participantsCount: {
		gridArea: 'participants',
		textAlign: 'center',
	},
	participantsPenalty: {
		gridArea: 'participants-penalty',
		textAlign: 'center',
		fontSize: '.8em',
		color: theme.colors.text.weak,
		alignSelf: 'center',
	},
	points: {
		gridArea: 'points',
		textAlign: 'right',
		color: theme.colors.text.alt,
	},
}),
{ name: 'TeamEventResultDetails' })

interface Props {
	className? : string,
	teamEventResult : TeamEventResult4FunTeamChampionship,
	rulesSet : RuleSets,
}

export default function TeamEventResultDetails(props : Props) {
	const { className } = props
	const classes = useStyles()

	const [ isOpen, setIsOpen ] = useState(false)
	
	const ter = props.teamEventResult

	return <div key={ter.eventPosition} className={classNames({ [classes.root]: true, [className || '']: className})} onClick={() => setIsOpen(!isOpen)}>
		<div className={classes.position}>{ter.eventPosition}</div>
		<div className={classes.teamName}>{ter.teamName}</div>
		<div className={classes.points}>{ter.eventPoints}</div>
		{isOpen &&
			<div className={classes.teamEventResult}>
				<header className={classes.teamEventResultHeader}>{ter.teamName}</header>
				{ter.driverResults.map((der, derIndex) => <div key={derIndex} className={classes.driverEventResult}>
					<header className={classes.driverEventResultHeader}>
						<span className={classes.driverName}>{der.driverName}</span> 
						<span className={classes.driverEventRaceResultsPoints}>{der.raceResultsPoints.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
						<span className={classes.driverEventQualiFastestPoints}>{der.qualifyingFastestLapResult.toLocaleString(undefined, { maximumFractionDigits: 1 })}</span>
						<span className={classes.driverEventRaceFastestPoints}>{der.raceFastestLapResult.toLocaleString(undefined, { maximumFractionDigits: 1 })}</span>
						<span className={classes.driverEventPoints}>{der.eventPoints.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span>
					</header>
					{der.results.map((dr, drIndex) => {
						const participantsPenalty = getRacePointsModifierByParticipants(dr.participantsCount, dr.split, props.rulesSet)
						return <div key={drIndex} className={classes.driverRaceResult}>
							<div className={classes.date}>{printDate(dr.dateTime)}</div>
							<div className={classes.time}>{printTime(dr.dateTime)}</div>
							<div className={classes.racePosition}>P{dr.raceResultPosition}</div>
							<div className={classes.raceSplit}>split {dr.split}</div>
							<div className={classes.participantsCount}>{dr.participantsCount} drivers</div>
							<div className={classes.participantsPenalty}>{participantsPenalty ? participantsPenalty + 'points' : ''}</div>
							<div className={classes.points}>{dr.points}</div>
						</div>
					})
					}
				</div>)}
			</div>
		}
	</div>
}
