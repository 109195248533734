import { array } from 'array-n';

export const arrayToN = (N : number) : number[] => array([ N ], x => x)

interface ArrayGroup<TGroupKey, TItem> {
	key: TGroupKey
	items: Array<TItem>
}

type GroupedArray<TGroupKey, TItem> = Array<ArrayGroup<TGroupKey, TItem>>

// from https://www.consolelog.io/group-by-in-javascript/ - with modifications
export default function groupBy<TItem, TGroupKey>(
	i_array : Array<TItem>, 
	i_groupKeyFunction : (i_item : TItem) => TGroupKey
	) : GroupedArray<TGroupKey, TItem> {
	return i_array.reduce(function(groups : GroupedArray<TGroupKey, TItem>, item : TItem) {
		const groupKey = i_groupKeyFunction(item)
		const groupIndex = groups.findIndex(g => g.key === groupKey)
		if (groupIndex >= 0) {
			groups[groupIndex].items.push(item)
		}
		else {
			groups.push({ key: groupKey, items: [ item ] })
		}
		return groups
	}, [])
}
