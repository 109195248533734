import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Roster4FunTeamChampionship, Team4FunTeamChampionship } from '../../services/DataManager'
import Button from '../Button'
import { Theme } from '../../Style'
import EditableLabel from '../EditableLabel'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {},
	team: {
		margin: [ '1em', 0 ],
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: theme.colors.mainRed,
	},
	teamHeader: {
		background: theme.gradients.red,
		padding: '1em',
	},
	teamDetails: {
		padding: '1em',
	},
	teamMember: {
		padding: [ '.5em', 0 ],
		display: 'grid',
		gridTemplate: '"name actions" auto / 1fr auto',
	},
}))

interface Props {
	className? : string,
	roster : Roster4FunTeamChampionship | null,
	onNewTeamInRoster : (i_roster : Roster4FunTeamChampionship) => void,
	onEditTeamInRoster : (i_roster : Roster4FunTeamChampionship, i_teamIndex : number, i_team : Team4FunTeamChampionship) => void,
}

export default function RosterPanel(props : Props) {
	const { className } = props
	const { roster } = props
	const { onNewTeamInRoster, onEditTeamInRoster } = props
	const classes = useStyles()

	function handleTeamNameChanged(i_teamIndex : number, i_newValue : string) : void {
		if (roster && roster.teams.length > i_teamIndex) {
			onEditTeamInRoster(roster, i_teamIndex, { ...roster.teams[i_teamIndex], name: i_newValue })
		}
	}

	function handleTeamMemberNameChanged(i_teamIndex : number, i_teamMemberIndex : number, i_newName : string) : void {
		if (roster && roster.teams.length > i_teamIndex && roster.teams[i_teamIndex].driversFullName.length > i_teamMemberIndex) {
			const team = roster.teams[i_teamIndex]
			team.driversFullName[i_teamMemberIndex] = i_newName
			onEditTeamInRoster(roster, i_teamIndex, team)
		}
	}
	
	function handleNewTeamMember(i_teamIndex : number) {
		if (roster && roster.teams.length > i_teamIndex) {
			const team = roster.teams[i_teamIndex]
			team.driversFullName.push('Team Member #' + (team.driversFullName.length + 1))
			onEditTeamInRoster(roster, i_teamIndex, team)
		}
	}

	function handleRemoveTeamMember(i_teamIndex : number, i_driverIndex : number) {
		if (roster && roster.teams.length > i_teamIndex && roster.teams[i_teamIndex].driversFullName.length > i_driverIndex) {
			const team = roster.teams[i_teamIndex]
			team.driversFullName = team.driversFullName.filter((dfn, i) => i !== i_driverIndex)
			onEditTeamInRoster(roster, i_teamIndex, team)
		}
	}
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			{
				roster 
				? <div>
					{roster.teams?.map((t, teamIndex) => <div key={teamIndex} className={classes.team}>
						<header className={classes.teamHeader}><EditableLabel value={t.name} onChange={i_newValue => handleTeamNameChanged(teamIndex, i_newValue)} /></header>
						<div className={classes.teamDetails}>
							<ul>
								{t.driversFullName.map((dfn, driverIndex) => <li key={driverIndex} className={classes.teamMember}>
									<EditableLabel value={dfn} onChange={i_newName => handleTeamMemberNameChanged(teamIndex, driverIndex, i_newName)} />
									<Button label="Remove" onPress={() => handleRemoveTeamMember(teamIndex, driverIndex)} />
								</li>)}
							</ul>
							<Button label="Add a new team member" onPress={() => handleNewTeamMember(teamIndex)} />
						</div>
					</div>)}
					<div className={classes.teamMember}>
						<Button label="Add a new team" onPress={() => onNewTeamInRoster(roster as Roster4FunTeamChampionship)} />
					</div>
				</div>
				: 'No roster available'
			}
		</div>
	);
};
