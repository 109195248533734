import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '../../Style'
import Button from '../Button'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		margin: [ '1rem', 0 ],
	},
}))

interface Props {
	className? : string,
	editions : number[] | null,
	selectEdition : (i_edition : number) => void,
	createNewEdition : () => void,
}

export default function EditionSelector(props : Props) {
	const { className } = props
	const classes = useStyles()
	const { editions, selectEdition, createNewEdition } = props
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			Edition: 
			{editions ? editions.map(e => <Button key={e} label={e} onPress={() => selectEdition(e)} />) : 'Caricamento...'}
			<Button label="New Edition" onPress={() => createNewEdition()} />
		</div>
	);
};
