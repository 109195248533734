import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import Button from './Button'
import { Theme } from '../Style'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {},
	display: {
		cursor: 'pointer',
		borderStyle: 'dotted',
		borderWidth: '1px',
		borderColor: 'transparent',
		'&:hover': {
			borderColor: theme.colors.background.alt,
		},
	},
}))

interface Props {
	className? : string,
	value : string | null | undefined,
	onChange : (i_newValue : string) => void,
}

export default function EditableLabel(props : Props) {
	const { className } = props
	const classes = useStyles()
	
	const [ isEditing, setEditing ] = useState(false);
	const [ currentValue, setCurrentValue ] = useState(props.value || '')
	const editInput = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		setCurrentValue(props.value || '')
	}, [ props.value ])

	useEffect(() => {
		if (isEditing) {
			editInput?.current?.focus()
		}
	}, [ isEditing ])

	function handleConfirm() { 
		props.onChange(currentValue)
		setEditing(false)
	}

	function handleCancel() { 
		setCurrentValue(props.value || '')
		setEditing(false)
	}

	function handleKeyPress(e : KeyboardEvent<HTMLInputElement>) {
		switch (e.key) {
			case 'Enter':
				handleConfirm()
				e.stopPropagation()
				return false
			case 'Escape':
				handleCancel()
				e.stopPropagation()
				return false
		}
	}
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			{
				isEditing
				? <span>
					<input ref={editInput} type="text" value={currentValue} onChange={e => setCurrentValue(e.target.value)}
						onKeyDown={e => handleKeyPress(e)} />
					<Button label="Confirm" onPress={() => handleConfirm()} />
					<Button label="Cancel" onPress={() => handleCancel()} />
				</span>
				: <span className={classes.display} onDoubleClick={() => setEditing(true)} title="Double click to edit">{props.value}</span>
			}
		</div>
	);
};
