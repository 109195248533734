import ForFunTeamChampionshipPage from "./pages/ForFunTeamChampionshipPage";
import HomePage from "./pages/HomePage";
import SingleRaceAnalysisPage from "./pages/SingleRaceAnalysisPage";

export interface Route {
	label : string,
	path : string, 
	exact : boolean,
	component : (props : any) => JSX.Element,
}

export const Routes = {
	home: { label: 'Dashboard', path: '/', exact: true, component: HomePage },
	singleRaceAnalysis: { label: 'Single Race Analysis', path: '/single-race-analysis', exact: true, component: SingleRaceAnalysisPage },
	_4FunTeamChampionship: { label: '4Fun Team Championship', path: '/4fun-team-championship', exact: true, component: ForFunTeamChampionshipPage },
}
