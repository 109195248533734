import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import ColorSchemeSelector from './ColorSchemeSelector'
import { Theme } from '../Style'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {	
		backgroundColor: theme.colors.background.stronger,
		color: theme.colors.text.main,
		display: 'grid',
		gridTemplate: '"logo . tools" auto / auto 1fr auto',
		padding: '1rem',
	},
	logo: {
		gridArea: 'logo',
		display: 'flex',
		alignItems: 'center',
	},
	logoString: {
		fontWeight: 'bold',
		fontStyle: 'italic',
		fontSize: '1.4em',
		margin: '0 1rem',
	},
	tools: {
		gridArea: 'tools',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'stretch',
	},
}))

interface Props {
	className?: string,
}

export default function Header(props : Props) {
	const { className } = props
	const classes = useStyles()
	
	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<header className={classes.logo}>
				<img src="icons/favicon-32x32.png" alt="Parsifal Studio" />
				<span className={classes.logoString}>Parsifal Studio</span>
			</header>
			<div className={classes.tools}>
				<ColorSchemeSelector />
			</div>
		</div>
	);
};
