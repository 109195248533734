import { DateTime } from "luxon"
import { dbPromise, ObjectStoreNames, Service } from "./DBService"

export interface Driver {
	id? : number,
	name : string | undefined,
	surname : string | undefined,
	fullNames : string[],
	nationality : string | undefined,
}

export interface Roster4FunTeamChampionship {
	id? : number,
	edition : number, 
	teams : Team4FunTeamChampionship[],
}

export interface Team4FunTeamChampionship {
	name : string,
	driversFullName : string[],
}

export interface DriverRaceResult4FunTeamChampionship {
	driverName : string,
	teamName : string,
	eventName : string,
	dateTime : DateTime,
	split : number,
	participantsCount : number,
	raceResultPosition : number,
	raceResultDNF : boolean,
	raceResultIncidentPoints : number,
	points : number,
}

export interface DriverEventResult4FunTeamChampionship {
	driverName : string, 
	eventName : string,
	eventTeamPosition : number, 
	eventOverallPosition : number,
	eventPoints : number,
	raceResultsPoints : number,
	results : Array<DriverRaceResult4FunTeamChampionship>,
	qualifyingFastestLapResult : number,
	raceFastestLapResult : number,
}

export interface TeamEventResult4FunTeamChampionship {
	teamName : string,
	eventName : string,
	eventPosition : number, 
	eventPoints : number,
	driverResults : Array<DriverEventResult4FunTeamChampionship>,
}

export interface TeamSeriesResult4FunTeamChampionship {
	teamName : string,
	position : number, 
	points : number,
	results : Array<TeamEventResult4FunTeamChampionship>,
}

export interface Event4FunTeamChampionship {
	name : string,
	shortName : string,
}

export interface SeriesStandings4FunTeamChampionship {
	series : string,
	events : Array<Event4FunTeamChampionship>,
	teamStandings : Array<TeamSeriesResult4FunTeamChampionship>,
}

export interface Standings4FunTeamChampionship {
	seriesStandings : Array<SeriesStandings4FunTeamChampionship>,
}

const DataManager = {
	
	getDriver: async (i_id : number) => {
		return await Service.query(ObjectStoreNames.Drivers, (d : Driver) => d.id === i_id)
	},
	saveDriver: async (i_driver : Driver) => {
		const idb = await dbPromise
		await idb.put(ObjectStoreNames.Drivers, i_driver)
	},

	getEditions4FunTC: async () => {
		return (await Service.query(ObjectStoreNames.Rosters4FunTeamChampionship, (r : Roster4FunTeamChampionship) => true))
			.map((r : Roster4FunTeamChampionship) => r.edition)
	},

	getRoster4FunTCByEdition: async (i_edition : number) : Promise<Roster4FunTeamChampionship | null> => {
		const rosters = (await Service.query(ObjectStoreNames.Rosters4FunTeamChampionship, (r : Roster4FunTeamChampionship) => r.edition === i_edition))
		if (rosters.length === 0) {
			return null
		}
		return rosters[0]
	},
	saveRoster4FunTC: async (i_roster : Roster4FunTeamChampionship) => {
		const idb = await dbPromise
		await idb.put(ObjectStoreNames.Rosters4FunTeamChampionship, i_roster)
	},

}

export default DataManager;
