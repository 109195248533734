import { DateTime } from "luxon";

export function printDate(i_dateTime : DateTime) : string {
	return i_dateTime.toFormat('dd/MM/yyyy')
}
export function printTime(i_dateTime : DateTime) : string {
	return i_dateTime.toFormat('HH:mm')
}
export function printDateTime(i_dateTime : DateTime) : string {
	return printDate(i_dateTime) + ' ' + printTime(i_dateTime)
}

export function formatTime(i_time : number) : string {
	if (!i_time && i_time !== 0.0) {
		return '---'
	}

	const hours : string = i_time >= 3600 ? Math.floor(i_time / 3600).toLocaleString(undefined, { minimumIntegerDigits: 1, maximumFractionDigits: 0 }) : '';
	const minutes : string = Math.floor(i_time % 3600 / 60).toLocaleString(undefined, { minimumIntegerDigits: 2, maximumFractionDigits: 0 });
	const seconds : string = Math.floor(i_time % 60).toLocaleString(undefined, { minimumIntegerDigits: 2, maximumFractionDigits: 0 });
	const milliseconds : string = Math.round(i_time % 1 * 1000).toLocaleString(undefined, { minimumIntegerDigits: 3, maximumFractionDigits: 0 });
	return `${hours}${hours ? ':' : ''}${minutes}:${seconds}.${milliseconds}`
}

export function formatGap(i_gap : number, i_lapsGap : number) : string {
	if (!i_gap && i_gap !== 0.0) {
		return '---'
	}

	if (i_lapsGap) {
		return `${i_lapsGap >= 0 ? '+' : '-'}${i_lapsGap} lap${Math.abs(i_lapsGap) > 1 ? 's' : ''}`
	}

	const hours = Math.abs(i_gap) >= 3600 ? Math.abs(Math.floor(i_gap / 3600)).toLocaleString(undefined, { minimumIntegerDigits: 1, maximumFractionDigits: 0 }) : '';
	const minutes = Math.abs(i_gap) >= 60 ? Math.abs(Math.floor(i_gap % 3600 / 60)).toLocaleString(undefined, { minimumIntegerDigits: hours ? 2 : 1, maximumFractionDigits: 0 }) : '';
	const seconds = Math.abs(Math.floor(i_gap % 60)).toLocaleString(undefined, { minimumIntegerDigits: minutes ? 2 : 1, maximumFractionDigits: 0 });
	const milliseconds = Math.abs(Math.round(i_gap % 1 * 1000)).toLocaleString(undefined, { minimumIntegerDigits: 3, maximumFractionDigits: 0 });
	return `${i_gap >= 0 ? '+' : '-'}${hours}${hours ? ':' : ''}${minutes}${minutes ? ':' : ''}${seconds}.${milliseconds}`
}
