import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Theme } from '../Style'
import { RuleSets, use4FunTeamChampionship } from '../hooks/use4FunTeamChampionship'
import EditionSelector from '../components/4fun-team-championship/EditionSelector'
import RosterPanel from '../components/4fun-team-championship/RosterPanel'
import StandingsPanel from '../components/4fun-team-championship/StandingsPanel'
import { RaceResult, SeasonFastestLapLeaderboards } from '../services/ParsifalTypes'
import { Standings4FunTeamChampionship } from '../services/DataManager'
import CollapsiblePanel from '../components/CollapsiblePanel'

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		background: theme.colors.background.main,
		color: theme.colors.text.main,
	},
}))

interface Props {
	className? : string,
}

export default function ForFunTeamChampionshipPage(props : Props) {
	const { className } = props
	const classes = useStyles()

	const { editions, createNewEdition } = use4FunTeamChampionship(0)
	const [ currentEdition, setCurrentEdition ] = useState(editions && editions.length > 0 ? editions[editions.length - 1] : 0)
	const { roster, addTeamInRoster, editTeamInRoster, computeStandingsFromResults } = use4FunTeamChampionship(currentEdition)
	const [ raceResults, setRaceResults ] = useState<Array<RaceResult> | null>(null)
	const [ fastestLapsLeaderboards, setFastestLapsLeaderboards ] = useState<SeasonFastestLapLeaderboards | null>(null)
	const [ standings, setStandings ] = useState<Standings4FunTeamChampionship | null>(null)

	useEffect(() => {
		let mounted = true
		if (mounted) {
			setCurrentEdition(editions && editions.length > 0 ? editions[editions.length - 1] : 0)
		}
		return () => {
			mounted = false
		}
	}, [ editions ])

	function handleNewRaceResults(i_raceResults : Array<RaceResult>) {
		setRaceResults(i_raceResults)
		setStandings(computeStandingsFromResults(i_raceResults, fastestLapsLeaderboards, RuleSets.Edition3))
	}
	
	function handleNewFastestLapsLeaderboards(i_fastestLapsLeaderboards : SeasonFastestLapLeaderboards) {
		setFastestLapsLeaderboards(i_fastestLapsLeaderboards)
		setStandings(computeStandingsFromResults(raceResults, i_fastestLapsLeaderboards, RuleSets.Edition3))
	}

	return (
		<div className={classNames({ [classes.root]: true, [className || '']: className})}>
			<h1>4Fun Team Championship</h1>
			<EditionSelector editions={editions} selectEdition={e => setCurrentEdition(e)} createNewEdition={() => { 
				const newEdition = createNewEdition()
				setCurrentEdition(newEdition)
			}} />
			<CollapsiblePanel isOpen={false} header="Roster" content={<RosterPanel roster={roster} 
				onNewTeamInRoster={(i_roster) => addTeamInRoster(i_roster, null)}
				onEditTeamInRoster={(i_roster, i_teamIndex, i_team) => editTeamInRoster(i_roster, i_teamIndex, i_team)}
				/>} />
			<CollapsiblePanel header="Standings" content={<StandingsPanel 
				standings={standings} 
				rulesSet={RuleSets.Edition3}
				onNewRaceResults={handleNewRaceResults}
				onNewFastestLapsLeaderboards={handleNewFastestLapsLeaderboards} 
				/>} />
		</div>
	)
}
