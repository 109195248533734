import classNames from 'classnames';
import { createUseStyles, ThemeProvider } from 'react-jss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import MainNavBar from './components/MainNavBar';
import { ThemeContext } from './contexts/ThemeContext';
import useColorScheme from './hooks/useColorScheme';
import { Routes } from './Routing';
import { getTheme } from './Style';

const useStyles = createUseStyles({
	root: {
		display: 'grid',
		width: '100%',
		height: '100%',
		gridTemplate: '"header header" auto "navbar page" 1fr / 16rem 1fr',
	},
	header: {
		gridArea: 'header',
	},
	navbar: {
		gridArea: 'navbar',
	},
	page: {
		gridArea: 'page',
		'& > *': {
			width: '100%',
			height: '100%',
			padding: '1em',
		},
	},
})

function App() {
	const classes = useStyles()
	const { colorScheme, setUserColorScheme } = useColorScheme()
	const theme = getTheme(colorScheme)

	return (
		<div className={classNames({ "App": true, [classes.root]: true })}>
			<ThemeContext.Provider value={{ theme, colorScheme, setColorScheme: i_colorScheme => setUserColorScheme(i_colorScheme) }}>
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<Header className={classes.header} />
						<MainNavBar className={classes.navbar} />
						<main className={classes.page}>
							<Switch>
							{
								Object.values(Routes)
								.map((route, index) => <Route key={index} path={route.path} exact={route.exact} component={route.component} />)						
							}
							</Switch>
						</main>
					</BrowserRouter>
				</ThemeProvider>
			</ThemeContext.Provider>
		</div>
	);
}

export default App;
