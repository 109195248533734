import { useState } from "react"

export default function useClipboard() : {
	content : string | null,
	setContent : ((i_content : string | any) => void) | null,
	read : (() => Promise<string>) | null,
} {
	const [ clipboardContent, setClipboardContent ] = useState<string | null>(null)
	
	if (!navigator.clipboard) {
		return { content: null, setContent: null, read: null }
	}

	navigator.clipboard.readText().then(t => setClipboardContent(t))

	return {
		content: clipboardContent,
		setContent: (i_content : string | any) => {
			let newContent = null
			switch (typeof i_content) {
				case 'string':
					newContent = i_content
					break
				default:
					newContent = JSON.stringify(i_content)
					break
			}
			navigator.clipboard.writeText(newContent)
		},
		read: () => {
			const promise = navigator.clipboard.readText()
			promise.then(t => setClipboardContent(t))
			return promise
		},
	}
}
