// https://blog.jimdhughes.com/indexeddb-react/
import * as idb from 'idb';

const DATABASE_NAME = 'PARSIFAL_DB';
const DATABASE_VERSION = 1;

export const ObjectStoreNames = {
	Drivers: 'Drivers',
	Rosters4FunTeamChampionship: 'Rosters4FunTC',
}

export const dbPromise = idb.openDB(DATABASE_NAME, DATABASE_VERSION, {
	upgrade: function (upgradeDb, oldVersion, newVersion, transaction) {
		console.debug('Upgrading database from version ' + oldVersion + ' to version ' + newVersion + '...');
		// eslint-disable-next-line default-case
		switch (oldVersion) {
			case 0:
				{
					upgradeDb.createObjectStore(ObjectStoreNames.Drivers, { keyPath: 'id', autoIncrement: true });
					upgradeDb.createObjectStore(ObjectStoreNames.Rosters4FunTeamChampionship, { keyPath: 'id', autoIncrement: true });
				}
			// eslint-disable-next-line no-fallthrough
		}
		console.debug('Database upgraded to version ' + newVersion + '.');
	},
});

export const DBService = ({

	get: (tablespace : string, key : string) => {
		return dbPromise.then(db => {
			return db.transaction(tablespace).objectStore(tablespace).get(key);
		}).catch(error => {
			// Do something?
		});
	},

	query: async (tablespace : string, filterFunction : (x : any) => boolean) => {
		const db = await dbPromise;
		let cursor = await db.transaction(tablespace, 'readonly').objectStore(tablespace).openCursor();
		let retval = [];
		while (cursor) {
			const filter = filterFunction(cursor.value);
			if (filter) {
				retval.push(cursor.value);
			}
			cursor = await cursor.continue();
		}
		return retval;
	},

	getAll: async (tablespace : string, indexName : string, index = []) => {
		const db = await dbPromise;
		let space : any = db.transaction(tablespace, 'readonly').objectStore(tablespace);
		if (indexName) {
			space = space.index(indexName);
		}
		return space.getAll(index);
	},

	put: (tablespace : string, object : any, key : string | null = null) => {
		return dbPromise.then(db => {
			if (key) {
				return db.transaction(tablespace, 'readwrite').objectStore(tablespace).put(object, key);
			}
			return db.transaction(tablespace, 'readwrite').objectStore(tablespace).put(object);
		}).catch(error => {
			// Do something?
		});
	},

	delete: (tablespace : string, key : string) => {
		return dbPromise.then(db => {
			return db.transaction(tablespace, 'readwrite').objectStore(tablespace).delete(key);
		}).catch(error => {
			// Do something?
		});
	},

	deleteAll: (tablespace : string) => {
		return dbPromise.then(db => {
			return db.transaction(tablespace, 'readwrite').objectStore(tablespace).clear();
		}).catch(error => {
			// Do something?
		});
	},
});

export const Service = DBService; 
