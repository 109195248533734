import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Theme } from '../Style';

export enum ButtonTypes {
	Regular,
	Primary,
}

const useStyles = createUseStyles((theme : Theme) => ({
	root: {
		display: 'inline-block',
		textDecoration: 'none',
		cursor: 'pointer',
		'&:active': {
			color: theme.colors.background.main,
			backgroundColor: theme.colors.text.main,
		},
		'&:focus': {
			outline: 'none',
		},
		'&:disabled': {
			cursor: 'not-allowed',
			filter: 'grayscale(80%) brightness(1.2)',
		},
	},
	primary: {
		//backgroundColor: theme.colors.mainRed,
		//color: theme.colors.text.main,
	},
}))

interface Props {
	className? : string,
	label : any,
	href? : string,
	type? : ButtonTypes,
	enabled? : boolean,
	onPress? : () => void,
}

export default function Button(props : Props) {
	const { className } = props
	const classes = useStyles()
	const { label, href, type = ButtonTypes.Regular, enabled = true } = props;
	const { onPress } = props;

	const classNameValue = classNames({ 
		[classes.root]: true, 
		[className || '']: className,
		[classes.primary]: type === ButtonTypes.Primary,
	});

	if (href) {
		return (
			<NavLink className={classNameValue} to={href}>
				{label}
			</NavLink>
		);
	}
	else {
		return (
			<button className={classNameValue} onClick={() => onPress && onPress()} disabled={!enabled}>
				{label}
			</button>
		);
	}
}
